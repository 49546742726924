<template>
  <div class="container tests">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/edit/test?testTypeId=${$route.params.testTypeId}`
            "
          >
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${this.$route.params.sectionId}`
            "
          >
            {{ word.toUpperFirstLetter(sectionName) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="action-btns">
      <div v-show="!isBatchEdit && !isEdit">
        <el-button
          style="width:150px; margin-bottom: 8px"
          type="success"
          @click="isEdit = true"
          ><i class="fa fa-plus"></i> 新增/删除题目</el-button
        >
      </div>
      <div v-show="!isBatchEdit && !isEdit">
        <el-button
          style="width:150px"
          type="success"
          @click="isBatchEdit = true"
          ><i class="fa fa-edit"></i> 批次編輯题目</el-button
        >
      </div>
      <div v-show="isBatchEdit">
        <el-button
          style="width:150px; margin-bottom: 8px"
          type="success"
          @click="batchUpdateQuestion"
        >
          {{ $t("button.save") }}
        </el-button>
      </div>
    </div>
    <div>
      <h1>{{ pageTitle }}</h1>
      <hr />
      <h2>顯示設定</h2>
      <el-switch
        v-model="displayOptions"
        active-text="顯示選項"
        inactive-text="隱藏選項"
        :active-value="true"
        :inactive-value="false"
      />
      <hr />
      <div v-if="isEdit" class="text-center">
        <el-button type="success" @click="addQuestion(1)" plain>
          <i class="fa fa-plus"></i> Add Question
        </el-button>
      </div>
      <div v-if="questions.length > 0">
        <div
          :key="question.order"
          v-for="question in isBatchEdit
            ? questions.slice(
                pageSize * (currentPage - 1),
                pageSize * currentPage
              )
            : questions"
        >
          <div v-if="isBatchEdit">
            <el-form
              style="margin: 12px 0px"
              label-width="100px"
              ref="form"
              :model="question"
            >
              <QuestionForm
                :isBatchEdit="true"
                :isEditMode="true"
                :question="question"
                :tags="tags"
                :passages="passages"
                @setQuestion="
                  value => {
                    setQuestion(question.order, value);
                  }
                "
                :testType="$route.params.testType"
                :sectionName="sectionName"
              />
            </el-form>
          </div>
          <div v-else>
            <Question
              :key="question.id"
              :question="question"
              :testType="$route.params.testType"
              :testTypeId="$route.params.testTypeId"
              :sectionId="$route.params.sectionId"
              :disableDelete="!isEdit"
              :displayOptions="displayOptions"
              @reload="fecthQuestions"
            />
            <div class="text-center">
              <el-button
                v-if="isEdit"
                type="success"
                @click="addQuestion(question.order + 1)"
                plain
                ><i class="fa fa-plus"></i> Add Question</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="isBatchEdit" style="margin: 16px 0px" class="text-center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="currentPage"
            @current-change="changeCurrentPage"
            :total="questions.length"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { word } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Question from "@/components/tests/Question.vue";
import QuestionForm from "@/components/tests/QuestionForm";
import testsApi from "@/apis/tests.js";
import tagApi from "@/apis/tag";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb, Question, QuestionForm },
  data() {
    return {
      currentPage: 1,
      isBatchEdit: false,
      sectionName: "",
      testName: "",
      questions: [],
      isEdit: false,
      displayOptions: false,
      tags: [],
      passages: [],
      question: {
        type: "default",
        id: null,
        content: "",
        order: null,
        tag_ids: [],
        options: [],
        passage_id: null,
        answer: "",
        exp: ""
      }
    };
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.Question");
    },
    pageSize() {
      return 5;
    },
    word() {
      return word;
    }
  },
  async created() {
    await this.fecthQuestions();
    const { full_name, subjects } = await testsApi.getTest(
      this.$route.params.testType,
      this.$route.params.testTypeId
    );
    this.testName = full_name;
    this.sectionName = subjects.find(
      subject => String(subject.id) === this.$route.params.sectionId
    ).name;
    this.passages = await testsApi.getSubjectPassages(
      this.$route.params.testType,
      this.$route.params.sectionId
    );
    this.getTags();
  },
  methods: {
    setQuestion(targetOrder, value) {
      this.questions = this.questions.map(question => {
        if (targetOrder === question.order) return { ...question, ...value };
        return question;
      });
    },
    questionFormFormatter(question) {
      return {
        ...this.question,
        ...question,
        options:
          question.options.length === 0
            ? this.question.options
            : question.options,
        tag_ids: question.tags.map(({ id }) => id),
        answer:
          question.answers.length === 0
            ? ""
            : question.answers[0].answers.join(","),
        passage_id: question.passage ? question.passage.id : 0,
        type: question.type === "math_range" ? "math" : question.type,
        is_aih: Boolean(question.is_aih),
        is_ais: Boolean(question.is_ais),
        is_coe: Boolean(question.is_coe),
        is_eoi: Boolean(question.is_eoi),
        is_hoa: Boolean(question.is_hoa),
        is_psada: Boolean(question.is_psada),
        is_ptam: Boolean(question.is_ptam),
        is_sec: Boolean(question.is_sec),
        is_wic: Boolean(question.is_wic)
      };
    },
    async fecthQuestions() {
      const questions = await testsApi.getSubjectQuestions(
        this.$route.params.testType,
        this.$route.params.sectionId
      );
      this.questions = questions.map(this.questionFormFormatter);
    },
    async addQuestion(order) {
      let questionContent = {};
      switch (this.$route.params.testType.toUpperCase()) {
        case "SAT":
          questionContent = {
            type: "default",
            order: order,
            content: "",
            exp: "",
            is_coe: false,
            is_wic: false,
            is_eoi: false,
            is_sec: false,
            is_hoa: false,
            is_psada: false,
            is_ptam: false,
            is_ais: false,
            is_aih: false,
            passage_id: 0,
            options: [],
            answers: [
              {
                answers: []
              }
            ],
            tag_ids: []
          };
          break;
        case "ACT":
          questionContent = {
            type: "default",
            order: order,
            content: "",
            exp: "",
            passage_id: 0,
            options: [],
            answers: [
              {
                answers: []
              }
            ],
            tag_ids: []
          };
          break;
      }
      let question = await testsApi.createSubjectQuestion(
        this.$route.params.testType,
        this.$route.params.sectionId,
        questionContent
      );
      this.$router.push({
        path: `/tests/${this.$route.params.testType}/test/${this.$route.params.testTypeId}/section/${this.$route.params.sectionId}/question/${question.id}/edit`
      });
    },
    getQuestionType(question) {
      if (question.type === "default") return "default";
      if (
        question.answer.indexOf("<") !== -1 ||
        question.answer.indexOf(">") !== -1 ||
        question.answer.indexOf("=") !== -1
      ) {
        return "math_range";
      }
      return "math";
    },
    getAnswer(question) {
      if (question.type === "default") {
        if (question.answer.indexOf(",") === -1) {
          return [{ answers: [question.answer] }];
        } else {
          return [{ answers: question.answer.split(",") }];
        }
      }
      return [{ answers: [question.answer] }];
    },
    async batchUpdateQuestion() {
      try {
        await testsApi.batchUpdateQuestion(
          this.$route.params.testType,
          this.questions.map(question => ({
            ...question,
            type: this.getQuestionType(question),
            answers: this.getAnswer(question)
          }))
        );
        this.$message.success(this.$t("message.update_success"));
        this.isBatchEdit = false;
        await this.fecthQuestions();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async getTags() {
      const { tags } = await tagApi.fetchTags();
      this.tags = tags;
    },
    changeCurrentPage(value) {
      this.currentPage = value;
      window.document.documentElement.scrollTop = 0;
    }
  }
};
</script>
<style scoped>
.box {
  background: #fefefe;
}
.tests {
  position: relative;
}
.action-btns {
  z-index: 9999999;
  position: fixed;
  top: 120px;
  right: 0px;
  margin-bottom: 15px;
  padding: 0 10px;
}
</style>
